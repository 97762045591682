.portal-app-bar {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  .login-bar {
    display: flex;
    .text-field {
      background-color: white;
      flex: 1;
      border-radius: 4px;
      margin-right: 10px;
    }
    .login-button {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .portal-app-bar {
    .login-bar {
      display: none;
    }
  }
}
