.gaming-screen {
  padding: 0vh;
  .title-bar {
    display: flex;
    padding-bottom: 2vh;
    margin: 0vh 3vw;
    .title {
      font-size: 5vh;
    }
    .icon {
      height: 6vh;
      width: 6vh;
      padding-left: 1vh;
    }
  }
  .game-container {
    // width: 80vw;
    // border-radius: 2vh;
    padding: 2vh 3vw;
    background-color: beige;
    color: #282c34;
    border: 1px solid lightgray;
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  }
}
