.landing-screen {
  display: flex;
  justify-content: center;
  color: #333;
  font-size: 17px;
  .content {
    margin: 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 980px;
    width: 100%;

    .left-side {
      flex: 1;
      margin-right: 60px;
      .left-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
      }

      .line-item {
        margin-top: 25px;
        display: flex;
        align-items: center;
        .icon {
          color: gray;
          font-size: 40px;
          margin-right: 10px;
        }
      }
    }

    .right-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      .right-header {
        .title-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right-title {
            font-size: 40px;
            margin-bottom: 5px;
            font-weight: 600;
          }
          .redirect-option {
            display: none;
          }
        }

        .right-subtitle {
          font-size: 17px;
        }
      }

      .fields-container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .text-field {
          background-color: white;
          flex: 1;
          border-radius: 4px;
        }
        .name-fields {
          display: flex;
          .left-field {
            margin-right: 10px;
          }
        }
      }
      .button {
        width: 50%;
        margin-top: 20px;
        text-transform: inherit;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .landing-screen {
    .content {
      padding-top: 10px;
      .left-side {
        display: none;
      }
      .right-side {
        .right-header {
          .title-line {
            .right-title {
              font-size: 30px;
              font-weight: 500;
            }
            .redirect-option {
              color: gray;
              display: inline;
              text-transform: inherit;
            }
          }

          .right-subtitle {
            display: none;
          }
        }
        .fields-container {
          .button {
            align-self: center;
          }
        }
      }
    }
  }
}
