.game-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #f3f6f8;
  border-radius: 3px;
  // border: 1px grey solid;
  // box-shadow: 1px 1px 1px grey;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  padding: 2vh;
  justify-content: space-between;

  .text {
    flex-direction: column;
    .title {
      font-size: 4vh;
      padding-bottom: 1vh;
      text-align: left;
    }
    .description {
      font-size: 2vh;
      text-align: left;
    }
  }

  .icon-container {
    padding-left: 1vh;
    .icon {
      width: 8vh;
      height: 8vh;
    }
  }

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
