.app-bar {
  flex-grow: 1;
  margin-bottom: 8vh;

  .title {
    flex-grow: 1;
    font-family: Impact;
    font-style: italic;
    text-shadow: 0px 0px 0px #111111;
  }
}

@media only screen and (max-width: 1000px) {
  .app-bar {
  }
}
