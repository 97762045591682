.madlib-madness {
  display: flex;
  flex-direction: column;
  .prompt {
    padding-bottom: 2vh;
  }
  .word-list {
    padding-left: 0.5vh;
    li {
      font-size: 2.5vh;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .save-button {
      margin-left: 1vw;
    }
  }
  .result-container {
    padding: 3vh;
    margin-top: 1vh;
    .paper-title {
      margin-bottom: 1vh;
      overflow-wrap: break-word;
    }
    .paper-body {
      overflow-wrap: break-word;
    }
  }
  .input-container {
    padding: 1.5vh 0vh;
  }
}
