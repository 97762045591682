.game-selection {
  text-align: center;
  padding: 0vh 5vw;
  padding-top: 100px;

  .selection-header {
    padding-bottom: 5vh;
    font-size: 6vh;
  }
}

.idea-selection {
  text-align: left;
  padding: 5vh 5vw;

  .selection-header {
    padding-bottom: 1vh;
    font-size: 3vh;
  }
}

@media only screen and (max-width: 768px) {
  .game-selection {
    padding-top: 10vh;
  }
}